<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }}</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label>Invoice No.</label>
                  <input type="text"
                         :class="['form-control', {'is-invalid': errorFor('invoice_no')}]"
                         v-model="form.invoice_no">
                  <v-errors :errors="errorFor('invoice_no')"/>
                </div>
                <div class="col-md-6">
                  <label>Product</label>

                  <select :class="['form-control', {'is-invalid': errorFor('product_id')}]"
                          :disabled="$route.name === 'inventories_edit'"
                          v-model="form.product_id">
                    <option value="">Select Product</option>
                    <option :value="product.id"
                            :key="'product_'+product.id"
                            v-for="product in products">{{ product.name }}</option>
                  </select>
                  <v-errors :errors="errorFor('product_id')"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Unit Price</label>
                  <input type="text"
                         v-no-commas
                         :class="['form-control', {'is-invalid': errorFor('unit_price')}]"
                         v-model="form.unit_price">
                  <v-errors :errors="errorFor('unit_price')"/>
                </div>
                <div class="col-md-6">
                  <label>Quantity</label>
                  <input type="text"
                         :class="['form-control', {'is-invalid': errorFor('quantity')}]"
                         v-model="form.quantity">
                  <v-errors :errors="errorFor('quantity')"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Date</label>
                  <input type="date"
                         :class="['form-control', {'is-invalid': errorFor('date')}]"
                         v-model="form.date">
                  <v-errors :errors="errorFor('date')"/>
                </div>
                <div class="col-md-6">
                  <label>Vendor</label>
                  <input type="text"
                         :class="['form-control', {'is-invalid': errorFor('vendor')}]"
                         v-model="form.vendor">
                  <v-errors :errors="errorFor('vendor')"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Note</label>
                  <textarea v-model="form.note"
                            :class="['form-control', {'is-invalid': errorFor('note')}]" rows="3">
                  </textarea>
                  <v-errors :errors="errorFor('note')"/>
                </div>
                <div class="col-md-6">
                  <label>Purchase URL</label>
                  <input type="url"
                         :class="['form-control', {'is-invalid': errorFor('purchase_url')}]"
                         v-model="form.purchase_url">
                  <v-errors :errors="errorFor('purchase_url')"/>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save
              </button>

              <router-link :to="{name: 'inventories'}"
                           class="btn btn-secondary">Cancel
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      title: 'Add Inventory',
      form: {
        invoice_no: '',
        product_id: '',
        unit_price: '',
        quantity: '',
        date: '',
        note: '',
        vendor: '',
        purchase_url: '',
      }
    }
  },
  created() {
    this.axios.get('/products')
      .then((response) => {
        this.products = response.data.data;
      })

    if (this.$route.name === 'inventories_edit') {
      this.axios.get('/inventories/'+this.$route.params.id)
        .then((response) => {
          this.form = response.data.data;

          delete this.form.id;
        })

      this.title = 'Edit Inventory';
    }
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'inventories_edit') {
        this.axios.patch('/inventories/'+this.$route.params.id, this.form)
          .then(() => {
            this.$router.push({name: 'inventories'});
            this.showSuccessMsg('Updated Successfully!')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.axios.post('/inventories', this.form)
          .then(() => {
            this.$router.push({name: 'inventories'});
            this.showSuccessMsg('Added Successfully!')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      }

    }
  }
}
</script>